import axios from 'axios';
import { dashboard } from '../util';

export function fetchAllAds( product_id, success, error ) {
	// data/ads.json
	axios.get( `${dashboard}/api/products/${product_id}/ads` ).then( res => success( res.data ) ).catch( error );
}

export function fetchAddAd( data, success, error ) {
	data._token = window.app && window.app._token ? window.app._token : '';
	axios.post( `${dashboard}/api/products/${data.product_id}/ads/new`, data ).then( res => success( res.data ) ).catch( error );
}

export function fetchDeleteAd( data, success, error ) {
	data._token = window.app && window.app._token ? window.app._token : '';
	axios.post( `${dashboard}/api/products/${data.product_id}/ads/${data.id}/delete`, data ).then( res => success( res.data ) ).catch( error );
}

export function fetchEditAd( data, success, error ) {
	data._token = window.app && window.app._token ? window.app._token : '';
	axios.post( `${dashboard}/api/products/${data.product_id}/ads/${data.id}/update`, data ).then( res => success( res.data ) ).catch( error );
}
