import { SET_MAIN_DRAWER_STATUS, SET_STATS_FILTER } from '../action-creators/types';

const INITIAL_STATE = {
	mainDrawerStatus: true,
	filter: 1,
	startDate: '',
	endDate: ''
};

const reducer = ( state = INITIAL_STATE, action ) => {
	switch( action.type ) {
		case SET_MAIN_DRAWER_STATUS:
			return {
				...state,
				mainDrawerStatus: action.payload,
			};

		case SET_STATS_FILTER:
			return {
				...state,
				filter: action.payload.filter,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			};
		default:
			return state;
	}
};
export default reducer;
