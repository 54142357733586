import React, { useState, useEffect } from 'react';

// State
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/index';

// MUI
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import { Edit, Delete } from '@material-ui/icons';

//Custom Components
import Loading from '../../../components/Loading';
import AddFormDialog from './FormDialog';
import EditFormDialog from './FormDialog';
import ConfirmDialog from './ConfirmDialog';

const Ads = () => {
	//local state
	const [addformDialogOpen, setAddFormDialogOpen] = useState(false);
	const [editformDialogOpen, setEditFormDialogOpen] = useState(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [editingData, setEditingData] = useState({});
	const [deletingData, setDeletingData] = useState(0);
	const [successAlertOpen, setSuccessAlertOpen] = useState(false);
	const [errorAlertOpen, setErrorAlertOpen] = useState(false);

	//actions
	const dispatch = useDispatch();
	const { getAllAds, addNewAd, deleteAd, editAd } = bindActionCreators(actionCreators, dispatch);

	//redux state
	const { ads, loading, isWaitingAction, messageSuccess, messageError } = useSelector(
		(state) => state.ads
	);
	const product_id = useSelector((state) => state.products.selectedProduct.data.id);

	useEffect(() => {
		getAllAds(product_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//handle actions
	const showErrorAlert = () => {
		setErrorAlertOpen(true);
		setSuccessAlertOpen(false);
		setTimeout(() => {
			setErrorAlertOpen(false);
		}, 5000);
	};

	const showSuccessAlert = () => {
		setErrorAlertOpen(false);
		setSuccessAlertOpen(true);
		setTimeout(() => {
			setSuccessAlertOpen(false);
		}, 5000);
	};

	const handleAddAd = (data) => {
		addNewAd(
			{ ...data, product_id },
			() => {
				setAddFormDialogOpen(false);
				showSuccessAlert();
			},
			() => {
				setAddFormDialogOpen(false);
				showErrorAlert();
			}
		);
	};
	const handleEditAd = (data, callback) => {
		editAd(
			{ ...data, product_id },
			() => {
				setEditFormDialogOpen(false);
				showSuccessAlert();
			},
			() => {
				setEditFormDialogOpen(false);
				showErrorAlert();
			}
		);
	};
	const handleDeleteAd = (id) => {
		deleteAd(
			{ id, product_id },
			() => {
				setConfirmDialogOpen(false);
				showSuccessAlert();
			},
			() => {
				setConfirmDialogOpen(false);
				showErrorAlert();
			}
		);
	};

	return (
		<>
			<header>
				<Grid container alignItems="center">
					<Grid item xs={6}>
						<Typography variant="h2">Ads</Typography>
					</Grid>
					<Grid item xs={6} container justifyContent="flex-end">
						<Button
							onClick={() => setAddFormDialogOpen(true)}
							startIcon={<AddIcon />}
							variant="contained"
							color="primary"
						>
							Create Ad
						</Button>
					</Grid>
				</Grid>
			</header>

			{errorAlertOpen && messageError && (
				<section>
					<Alert severity="error">{messageError}</Alert>
				</section>
			)}

			{successAlertOpen && messageSuccess && (
				<section>
					<Alert severity="success">{messageSuccess}</Alert>
				</section>
			)}

			{loading && <Loading />}

			{ads.length <= 0 && !loading && (
				<section>
					<Alert severity="info">No Ads Yet</Alert>
				</section>
			)}

			{ads.length > 0 && !loading && (
				<section>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Iframe URL</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{ads.map((item, index) => {
								return (
									<TableRow key={index}>
										<TableCell>{item.name}</TableCell>
										<TableCell>{item.link_url}</TableCell>
										<TableCell>{item.active ? 'Active' : 'Inactive'}</TableCell>
										<TableCell>
											<IconButton
												onClick={() => {
													setEditingData(item);
													setEditFormDialogOpen(true);
												}}
												color="primary"
											>
												<Edit />
											</IconButton>
											<IconButton
												onClick={() => {
													setDeletingData(item.id);
													setConfirmDialogOpen(true);
												}}
												color="primary"
											>
												<Delete />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</section>
			)}
			<AddFormDialog
				open={addformDialogOpen}
				onClose={() => setAddFormDialogOpen(false)}
				onSendForm={handleAddAd}
				title="Add New Banner"
				actionButtonText="Add"
				loading={isWaitingAction}
			/>
			<EditFormDialog
				open={editformDialogOpen}
				onClose={() => setEditFormDialogOpen(false)}
				onSendForm={handleEditAd}
				data={editingData}
				title="Edit Ad Form"
				actionButtonText="Edit"
				loading={isWaitingAction}
			/>
			<ConfirmDialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				onSendForm={handleDeleteAd}
				data={deletingData}
				title="Are you sure you want to delete this Ad?"
				description="if you confirm this action you will not be able to recover the ad information, and the item will disappear from the user new tab right away "
				actionButtonText="Confirm"
				loading={isWaitingAction}
			/>
		</>
	);
};

export default Ads;
