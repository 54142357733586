import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import logo from '../images/tomato-soup.png';

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	menuIcon: {
		color: theme.palette.textGrey,
		fontSize: 24,
	},
	title: {
		marginLeft: theme.spacing(1),
		fontWeight: 300,
		color: theme.palette.textGrey,
	},
	logo: {
		height: 30,
	},
}));

const TopBar = ({ onMenuPress }) => {
	const classes = useStyles();

	return (
		<AppBar color="secondary" position="fixed" className={classes.appBar}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={onMenuPress}
					edge="start"
					className={classes.menuButton}
				>
					<MenuIcon className={classes.menuIcon} />
				</IconButton>
				<Link to="/">
					<img src={logo} alt="Seitz Network" className={classes.logo} />
				</Link>
				<Typography variant="subtitle1" className={classes.title}>
					Extensions Manager
				</Typography>
			</Toolbar>
		</AppBar>
	);
};
export default TopBar;
