import React, { useEffect, useState } from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { DropzoneArea } from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
	dropzone: {
		width: '100%',
		minHeight: 150,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	dropzoneParagraph: {
		marginTop: 10,
		marginBottom: 5,
	},
	icon: {
		width: 60,
		height: 60,
	},
	image: {
		maxWidth: '100%',
	},
    
}));

export default function ImageUpload({ image, type, text, onChange }) {
	const classes = useStyles();

	const [imageFile, setImageFile] = useState(image);
	const [imageFileURI, setImageFileURI] = useState(image);

	useEffect(() => {
		if (imageFile && imageFile.type) {
			let reader = new FileReader();
			reader.onload = function (e) {
				setImageFileURI(e.target.result);
			};
			reader.readAsDataURL(imageFile);
		}
	}, [imageFile]);

	const uploadImage = (files) => {
		if (files.length > 0) {
			setImageFile(files[0]);
			onChange(files[0]);
		} else {
			setImageFile(null);
			onChange(null);
		}
	};

	const handleDeleteImageClick = () => {
		setImageFile(null);
		setImageFileURI('');
		onChange('');
	};

	return imageFile && imageFileURI ? (
		<Box className={classes.previewBox}>
			<img
				alt=""
				variant="square"
				className={type === 'image' ? classes.image : classes.icon}
				src={imageFileURI}
			/>
			<IconButton onClick={handleDeleteImageClick} aria-label="delete">
				<DeleteIcon fontSize="small" />
			</IconButton>
		</Box>
	) : (
		<DropzoneArea
			dropzoneText={text}
			filesLimit={1}
			onChange={uploadImage}
			showPreviews={false}
			dropzoneClass={classes.dropzone}
			dropzoneParagraphClass={classes.dropzoneParagraph}
		/>
	);
}
