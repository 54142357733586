import React from 'react';
import clsx from 'clsx';

// State
import { actionCreators } from './state/index';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

// Navigation
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

// App Components
import Drawer from './components/Drawer';
import AppBar from './components/AppBar';
import Footer from './components/Footer';

// App Pages
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail/';

// import Account from './pages/Account';
import Stats from './pages/Stats/';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -theme.drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

const App = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { setMainDrawerStatus } = bindActionCreators(actionCreators, dispatch);
	const { mainDrawerStatus } = useSelector((state) => state.app);

	return (
		<Router>
			<div className={classes.root}>
				<AppBar onMenuPress={() => setMainDrawerStatus(!mainDrawerStatus)} />
				<Drawer status={mainDrawerStatus} />
				<div
					className={clsx(classes.content, {
						[classes.contentShift]: mainDrawerStatus,
					})}
				>
					<main>
						<Toolbar />
						<Switch>
							<Route path="/products/:id">
								<ProductDetail />
							</Route>
							<Route path="/stats">
								<Stats />
							</Route>
							<Route path="/products">
								<Products />
							</Route>
							<Route exact path="/">
								<Redirect to="/products" />
							</Route>
						</Switch>
					</main>
					<Footer />
				</div>
			</div>
		</Router>
	);
};

export default App;
