import {
	GET_ADS,
	GET_ADS_ERROR,
	GET_ADS_SUCCESS,
	ADD_NEW_AD,
	ADD_NEW_AD_ERROR,
	ADD_NEW_AD_SUCCESS,
	EDIT_AD,
	EDIT_AD_ERROR,
	EDIT_AD_SUCCESS,
	DELETE_AD,
	DELETE_AD_ERROR,
	DELETE_AD_SUCCESS,
} from '../action-creators/types';

const INITIAL_STATE = {
	ads: [],
	loading: true,
	messageError: '',
	isWaitingAction: false,
	messageSuccess: '',
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_ADS:
			return {
				...state,
				messageError: '',
				loading: true,
			};
		case GET_ADS_ERROR:
			return {
				...state,
				loading: false,
				messageError: action.payload,
			};
		case GET_ADS_SUCCESS:
			return {
				...state,
				messageError: '',
				loading: false,
				ads: action.payload,
			};
		case ADD_NEW_AD:
			return {
				...state,
				messageError: '',
				isWaitingAction: true,
				messageSuccess: '',
			};
		case ADD_NEW_AD_ERROR:
			return {
				...state,
				messageError: action.payload,
				isWaitingAction: false,
				messageSuccess: '',
			};
		case ADD_NEW_AD_SUCCESS:
			return {
				...state,
				messageError: '',
				isWaitingAction: false,
				messageSuccess: 'Ad added succesfuly!',
			};
		case EDIT_AD:
			return {
				...state,
				messageError: '',
				isWaitingAction: true,
				messageSuccess: '',
			};
		case EDIT_AD_ERROR:
			return {
				...state,
				messageError: action.payload,
				isWaitingAction: false,
				messageSuccess: '',
			};
		case EDIT_AD_SUCCESS:
			return {
				...state,
				messageError: '',
				isWaitingAction: false,
				messageSuccess: 'Ad edited succesfuly!',
			};
		case DELETE_AD:
			return {
				...state,
				messageError: '',
				isWaitingAction: true,
				messageSuccess: '',
			};
		case DELETE_AD_ERROR:
			return {
				...state,
				messageError: action.payload,
				isWaitingAction: false,
				messageSuccess: '',
			};
		case DELETE_AD_SUCCESS:
			return {
				...state,
				messageError: '',
				isWaitingAction: false,
				messageSuccess: 'Ad deleted succesfuly!',
			};
		default:
			return state;
	}
};
export default reducer;
