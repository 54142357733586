import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	blockquote: {
		borderLeft: `2px solid ${theme.palette.primary.main}`,
		color: '#5f6368',
		padding: '8px 0 8px 16px',
	},
}));

const Blockquote = (props) => {
	const classes = useStyles();

	return (
		<blockquote className={classes.blockquote}>
			<Typography variant="body2">{props.children}</Typography>
		</blockquote>
	);
};
export default Blockquote;
