import React from 'react';

// MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';

// Form State
import { Formik } from 'formik';

const useStyle = makeStyles( ( theme ) => ( {
	select: {
		minWidth: 300,
	},
	input: {
		minWidth: 300,
	},
	form: {
		minWidth: 500,
		paddingTop: 4,
	},
	progress: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 6,
	},
} ) );

// Component
export default function NewProductDialog( {
	title,
	description,
	actionButtonText,
	open,
	onClose,
	onSendForm,
} ) {
	const classes = useStyle();

	const initialValues = { name: '', type: '' };

	var handleClose = ( e, r ) => {
		if( r === 'backdropClick' ) return;

		onClose( e );
	};

	const validate = ( values ) => {
		const errors = {};
		if( values.name === '' ) {
			errors.name = 'Name is Required';
		}
		if( values.type === '' ) {
			errors.type = 'Type is Required';
		}
		return errors;
	};

	const onSubmit = ( values, { setSubmitting } ) => {
		onSendForm( values, () => {
			setSubmitting( false );
		} );
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<Box className={classes.form}>
				<Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
					{( { values, errors, touched, handleChange, handleSubmit, isSubmitting } ) => (
						<>
							{isSubmitting && <LinearProgress className={classes.progress} />}
							<DialogTitle id="form-dialog-title">{title}</DialogTitle>
							<DialogContent>
								{description && (
									<DialogContentText>{description}</DialogContentText>
								)}

								<Box component="fieldset" my={3}>
									<FormControl fullWidth>
										<TextField
											variant="outlined"
											value={values.name}
											onChange={handleChange}
											disabled={isSubmitting}
											error={errors.name && touched.name}
											helperText={errors.name && touched.name && errors.name}
											className={classes.input}
											name="name"
											label="Product Name"
											type="text"
											autoComplete="off"
											autoFocus
										/>
									</FormControl>
								</Box>
								<Box component="fieldset">
									<FormControl variant="outlined" fullWidth error={!!errors.type}>
										<InputLabel htmlFor="type-label">Type</InputLabel>
										<Select
											disabled={isSubmitting}
											label="Type"
											labelId="type-label"
											value={values.type}
											onChange={handleChange}
											name="type"
											className={classes.select}
										>
											<MenuItem value="extension">Extension</MenuItem>
											<MenuItem value="app">Desktop App</MenuItem>
											<MenuItem value="launcher">Mobile Launcher</MenuItem>
										</Select>
										{errors.type && (
											<FormHelperText>{errors.type}</FormHelperText>
										)}
									</FormControl>
								</Box>
							</DialogContent>

							<DialogActions>
								<Button
									variant="contained"
									disabled={isSubmitting}
									onClick={handleSubmit}
									color="primary"
								>
									{actionButtonText}
								</Button>
								<Button
									disabled={isSubmitting}
									onClick={handleClose}
									color="primary"
								>
									Cancel
								</Button>
							</DialogActions>
						</>
					)}
				</Formik>
			</Box>
		</Dialog>
	);
}
