import React, { useEffect } from 'react';

// State
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';

// Navigation
import { useRouteMatch, Switch, Route, useParams } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Custom Components
import Menu from './Menu';
import AppBar from './TopBar';
import Details from './Details/';
import Ads from './Ads/';
import Shortcuts from './Shortcuts/';
import Stats from './Stats/';
import Loading from '../../components/Loading';

const useStyles = makeStyles( ( theme ) => ( {
	menu: {
		width: '100%',
		maxWidth: 230,
		borderRight: '1px solid #e7eaed',
	},
	container: {
		paddingTop: 74,
	},
} ) );

export default function ProductDetail() {
	const classes = useStyles();
	let match = useRouteMatch();
	let { id } = useParams();

	id = parseInt( id );

	//actions
	const dispatch = useDispatch();
	const { setMainDrawerStatus, getSingleProduct } = bindActionCreators( actionCreators, dispatch );

	//redux state
	const { selectedProduct } = useSelector( ( state ) => state.products );

	useEffect( () => {
		if( selectedProduct.data.id !== id ) {
			getSingleProduct( id );
		}
		setMainDrawerStatus( false );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] );

	if( selectedProduct.data.id !== id || selectedProduct.loading ) {
		return <Loading />;
	}

	return (
		<>
			<AppBar product={selectedProduct.data} />
			<Grid className={classes.container} container>
				<Grid className={classes.menu} item xs>
					<Menu />
				</Grid>
				<Grid item xs>
					<main>
						<Switch>
							<Route exact path={match.path}>
								<Details />
							</Route>
							<Route path={`${match.path}/shortcuts`}>
								<Shortcuts />
							</Route>
							<Route path={`${match.path}/ads`}>
								<Ads />
							</Route>
							<Route path={`${match.path}/stats`}>
								<Stats />
							</Route>
						</Switch>
					</main>
				</Grid>
			</Grid>
		</>
	);
}
