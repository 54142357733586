import axios from 'axios';
import { dashboard } from '../util';

export function fetchAddProduct( data, success, error ) {
    data._token = window.app && window.app._token ? window.app._token : '';
    axios.post( `${dashboard}/api/products/new`, data ).then( ( res ) => success( res.data ) ).catch( error );
};

export function fetchEditProduct( data, success, error ) {
    data._token = window.app && window.app._token ? window.app._token : '';
    const formData = new FormData();
    for( var key in data ) {
        formData.append( key, data[key] );
    }
    axios.post( `${dashboard}/api/products/${data.product_id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } } ).then( ( res ) => success( res.data ) ).catch( error );
}

export function fetchAllProducts( success, error ) {
    // data/products.json
    axios.get( `${dashboard}/api/products` ).then( res => success( res.data ) ).catch( error );
}

export function fetchSingleProduct( id, success, error ) {
    // data/products.json
    axios.get( `${dashboard}/api/products/${id}` ).then( ( res ) => success( res.data ) ).catch( error );
};