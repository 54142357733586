import { GET_STATS, GET_STATS_ERROR, GET_STATS_SUCCESS } from './types';

import { fetchStats } from '../../api';

export const getStats = ( data ) => {
	return ( dispatch, getState ) => {
		dispatch( { type: GET_STATS, payload: { type: data.type } } );
		const { filter, startDate, endDate } = getState().app;
		data = {
			...data,
			filter,
			startDate,
			endDate
		};
		fetchStats(
			data,
			( response ) => {
				dispatch( { type: GET_STATS_SUCCESS, payload: { ...response, type: data.type } } );
			},
			( error ) => {
				dispatch( {
					type: GET_STATS_ERROR,
					payload: { error: error.message, type: data.type },
				} );
			}
		);
	};
};

const fetchStatsAsPromise = ( data ) => {
	return new Promise( function( resolve, reject ) {
		fetchStats(
			data,
			( response ) => resolve( response ),
			( error ) => reject( Error( error ) )
		);
	} );
};

export const getGlobalStats = ( type, globalType ) => {
	return ( dispatch, getState ) => {
		dispatch( { type: GET_STATS, payload: { type: globalType } } );
		const { filter, startDate, endDate } = getState().app;
		const data = {
			product_id: 'all',
			type: type,
			filter,
			startDate,
			endDate
		};
		fetchStats(
			data,
			( response ) => {
				dispatch( {
					type: GET_STATS_SUCCESS,
					payload: { ...response, type: globalType }
				} );
			},
			( error ) => {
				dispatch( {
					type: GET_STATS_ERROR,
					payload: { error: error.message, type: globalType },
				} );
			}
		);
	};
};
