import React from 'react';

// Libs
import { Formik } from 'formik';

// MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyle = makeStyles((theme) => ({
	input: {
		minWidth: 300,
	},
	form: {
		minWidth: 500,
		paddingTop: 4,
	},
	progress: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 6,
	},
}));

// Component
export default function FormDialog({
	open,
	onClose,
	onSendForm,
	title,
	description,
	actionButtonText,
	data = null,
}) {
	const classes = useStyle();

	const initialValues = data
		? data
		: {
				name: '',
				link_url: '',
				favicon_domain: '',
		  };

	var handleClose = (e, r) => {
		if (r === 'backdropClick') return;
		onClose(e);
	};

	const validate = (values) => {
		const errors = {};
		if (values.name === '') {
			errors.name = 'Name is Required';
		}
		if (values.link_url === '') {
			errors.link_url = 'Url is Required';
		}
		if (values.favicon_domain === '') {
			errors.favicon_domain = 'Favicon is Required';
		}
		return errors;
	};

	const onSubmit = (values, { setSubmitting }) => {
		onSendForm(values);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<Box className={classes.form}>
				<Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
					{({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
						<>
							{isSubmitting && <LinearProgress className={classes.progress} />}
							<DialogTitle id="form-dialog-title">{title}</DialogTitle>

							<DialogContent>
								{description && (
									<Box mb={3}>
										<DialogContentText>{description}</DialogContentText>
									</Box>
								)}
								<Box component="fieldset" mb={3}>
									<FormControl fullWidth>
										<TextField
											variant="outlined"
											value={values.name}
											onChange={handleChange}
											disabled={isSubmitting}
											error={errors.name && touched.name}
											helperText={errors.name && touched.name && errors.name}
											className={classes.input}
											name="name"
											label="Favicon Name"
											type="text"
											autoComplete="off"
											autoFocus
										/>
									</FormControl>
								</Box>
								<Box component="fieldset" my={3}>
									<FormControl fullWidth>
										<TextField
											variant="outlined"
											value={values.link_url}
											onChange={handleChange}
											disabled={isSubmitting}
											error={errors.link_url && touched.link_url}
											helperText={
												errors.link_url &&
												touched.link_url &&
												errors.link_url
											}
											className={classes.input}
											name="link_url"
											label="URL (AFF link)"
											type="url"
											autoComplete="off"
										/>
									</FormControl>
								</Box>
								<Box component="fieldset">
									<FormControl fullWidth>
										<TextField
											variant="outlined"
											value={values.favicon_domain}
											onChange={handleChange}
											disabled={isSubmitting}
											error={errors.favicon_domain && touched.favicon_domain}
											helperText={
												errors.favicon_domain &&
												touched.favicon_domain &&
												errors.favicon_domain
											}
											className={classes.input}
											name="favicon_domain"
											label="Favicon URL (Domain)"
											type="url"
											autoComplete="off"
										/>
									</FormControl>
								</Box>
							</DialogContent>

							<DialogActions>
								<Button
									variant="contained"
									disabled={isSubmitting}
									onClick={handleSubmit}
									color="primary"
								>
									{actionButtonText}
								</Button>
								<Button
									disabled={isSubmitting}
									onClick={handleClose}
									color="primary"
								>
									Cancel
								</Button>
							</DialogActions>
						</>
					)}
				</Formik>
			</Box>
		</Dialog>
	);
}
