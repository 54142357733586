import React from 'react';

// Navigation
import { useLocation } from 'react-router-dom';

// State
import { useSelector } from 'react-redux';

// MUI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '5px 0px',
		boxShadow: '0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 2px 6px 2px rgb(60 64 67 / 15%)',
		top: 64,
		background: '#fff',
		left: 'auto',
		right: 'auto',
	},
	title: {
		flexGrow: 1,
		color: theme.palette.primary.main,
		fontSize: '1.375rem',
		marginBottom: 4,
	},
	subtitle: {
		color: theme.palette.textGrey,
	},
	menuIcon: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginRight: theme.spacing(1),
	},
}));

const TopBar = ({ product }) => {
	const classes = useStyles();
	const location = useLocation();
	const { name, type, activeUsers, icon } = product;

	//local state
	const [anchorEl, setAnchorEl] = React.useState(null);

	//redux state
	const { allProducts } = useSelector((state) => state.products);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (id) => {
		setAnchorEl(null);
	};

	return (
		<AppBar color="transparent" className={classes.root} position="fixed">
			<Toolbar>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Avatar variant="square" src={icon} />
					</Grid>
					<Grid item>
						<Typography variant="h1" className={classes.title}>
							{name}
						</Typography>
						<Typography variant="body2" className={classes.subtitle}>
							Type: {type} - {activeUsers} active users
						</Typography>
					</Grid>
				</Grid>
				<div>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						{allProducts.map((product, index) => {
							return (
								<MenuItem
									key={index}
									onClick={() => {
										let currentPage = location.pathname.split('/').pop();
										if (
											currentPage === 'stats' ||
											currentPage === 'shortcuts' ||
											currentPage === 'ads'
										)
											window.location.href = `/products/${product.id}/${currentPage}`;
										else window.location.href = `/products/${product.id}`;
									}}
								>
									<Avatar
										className={classes.menuIcon}
										variant="square"
										src={product.icon}
									/>
									{product.name}
								</MenuItem>
							);
						})}
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default TopBar;
