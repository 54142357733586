import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
// import AccountCircle from '@material-ui/icons/AccountCircle';

import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import List from '@material-ui/core/List';

const useStyles = makeStyles( ( theme ) => ( {
	drawer: {
		width: theme.drawerWidth,
		flexShrink: 0,
		border: 'none',
	},
	drawerPaper: {
		width: theme.drawerWidth,
		border: 'none',
		background: theme.palette.secondary.main,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing( 0, 1 ),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
} ) );

const menuItems = [
	{ path: '/products', name: 'All Products', icon: <ExtensionOutlinedIcon /> },
	{ path: '/stats', name: 'General Stats', icon: <InsertChartOutlinedIcon /> },
	// { path: '/account', name: 'Account', icon: <AccountCircle /> },
];

const MyDrawer = ( { status } ) => {
	const classes = useStyles();
	const location = useLocation();

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="left"
			open={status}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<Toolbar />
			<List>
				{menuItems.map( ( item, index ) => {
					return (
						<ListItem
							key={index}
							component={Link}
							to={item.path}
							button
							selected={location.pathname.includes( item.path )}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.name} />
						</ListItem>
					);
				} )}
			</List>
		</Drawer>
	);
};
export default MyDrawer;
