import React, { useEffect, useState } from 'react';

// State
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';

// Navigation
import { useHistory } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

//Custom Components
import ProductsList from './ProductsList';
import AddProductDialog from './AddProductDialog';
import Loading from '../../components/Loading';

//Utils
import { FormatNumber } from '../../util';

const useStyles = makeStyles( ( theme ) => ( {
	product: {
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	label: {
		color: theme.palette.textGrey,
	},
} ) );

const Home = () => {
	const classes = useStyles();
	const history = useHistory();
	//local state
	const [addFormDialogOpen, setAddFormDialogOpen] = useState( false );
	const [errorAlertOpen, setErrorAlertOpen] = useState( false );

	//actions
	const dispatch = useDispatch();
	const { setMainDrawerStatus, getAllProducts, addProduct } = bindActionCreators(
		actionCreators,
		dispatch
	);

	//redux state
	const { allProducts, loading } = useSelector( ( state ) => state.products );

	useEffect( () => {
		setMainDrawerStatus( true );
		getAllProducts();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] );

	const handleAddProduct = ( data, callback ) => {
		addProduct(
			data,
			( data ) => {
				callback();
				history.push( `products/${data.id}` );
			},
			( error ) => {
				callback();
				setAddFormDialogOpen( false );
				setErrorAlertOpen( true );
				setTimeout( () => {
					setErrorAlertOpen( false );
				}, 5000 );
			}
		);
	};

	const getTotals = ( value ) => {
		return FormatNumber( allProducts.reduce( ( previous, key ) => previous + key[value], 0 ) );
	};

	return (
		<>
			<header>
				<Grid container alignItems="center">
					<Grid item xs={6}>
						<Typography variant="h2">Products</Typography>
					</Grid>
					<Grid item xs={6} container justifyContent="flex-end">
						<Button
							onClick={() => setAddFormDialogOpen( true )}
							startIcon={<AddIcon />}
							variant="contained"
							color="primary"
						>
							Add Product
						</Button>
					</Grid>
				</Grid>
				{/* <Box mt={2}>
					<Button variant="outlined">Show Details</Button>
				</Box> */}
			</header>
			{errorAlertOpen && (
				<section>
					<Alert severity="error">
						There was a problem creating the product. Try again later
					</Alert>
				</section>
			)}
			<section>
				{loading ? (
					<Loading />
				) : (
					<Table style={{ tableLayout: 'auto' }}>
						<TableHead>
							<TableRow>
								<TableCell>Product</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Installs</TableCell>
								<TableCell>Today Installs</TableCell>
								<TableCell>Today UnInstalls</TableCell>
								<TableCell>Today Searches</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<ProductsList products={allProducts} />
							<TableRow>
								<TableCell>
									<Box ml={8}>
										<Grid container spacing={3} alignItems="center">
											<Grid item>
												<Typography
													className={classes.product}
													variant="body2"
												>
													Total
												</Typography>
												<Typography
													className={classes.label}
													variant="caption"
												>
													Active Users: {getTotals( 'activeUsers' )}
												</Typography>
											</Grid>
										</Grid>
									</Box>
								</TableCell>
								<TableCell>All</TableCell>
								<TableCell>{getTotals( 'installs' )}</TableCell>
								<TableCell>{getTotals( 'todayInstalls' )}</TableCell>
								<TableCell>{getTotals( 'todayUninstalls' )}</TableCell>
								<TableCell>{getTotals( 'todaySearches' )}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}
			</section>
			<AddProductDialog
				title="Add New Product"
				description="Start adding a new product by chosen a name and a type, you will need to add more information later"
				actionButtonText="Create"
				open={addFormDialogOpen}
				onClose={() => setAddFormDialogOpen( false )}
				onSendForm={handleAddProduct}
			/>
		</>
	);
};

export default Home;
