import React, { useState } from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';

// Form State
import { Formik } from 'formik';

// Components
import Blockquote from '../../../components/Blockquote';
import ImageUpload from '../../../components/ImageUpload';

const useStyles = makeStyles( ( theme ) => ( {
	label: {
		color: theme.palette.textGrey,
		lineHeight: 1,
	},
	progress: {
		position: 'fixed',
		left: 0,
		zIndex: 9999,
		right: 0,
		top: 139,
		height: 6,
	},
} ) );

const Form = ( { onSendForm, data } ) => {
	const classes = useStyles();

	const [iconFile, setIconFile] = useState( data.icon );
	const [backgroundFile, setBackgroundFile] = useState( data.background );

	const initialValues = {
		name: data.name,
		icon: data.icon,
		type: data.type,
		search_url: data.search_url,
		search_url_dsp: data.search_url_dsp,
		search_url_srch: data.search_url_srch,
		pixel: data.pixel,
		pixel_50: data.pixel_50,
		background: data.background,
		hideTopBar: data.hideTopBar,
		hideTopBarBy: data.hideTopBarBy,
		hideTopBarFrequency: data.hideTopBarFrequency,
	};

	const validate = ( values ) => {
		const errors = {};
		if( values.name === '' ) {
			errors.name = 'Product Name is Required';
		}
		if( values.search_url === '' ) {
			errors.search_url = 'Search URL is Required';
		}
		return errors;
	};

	const onSubmit = ( values, { setSubmitting } ) => {
		values.icon = iconFile ? iconFile : '';
		values.background = backgroundFile ? backgroundFile : '';

		onSendForm( values, () => {
			setSubmitting( false );
		} );
	};

	return (
		<Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
			{( {
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			} ) => (
				<>
					<section>
						<Grid container spacing={3}>
							<Grid item xs={2}>
								<Typography variant="h5">Product Information</Typography>
							</Grid>
							<Grid item xs={8}>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.name}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.name && touched.name}
													helperText={
														errors.name && touched.name && errors.name
													}
													name="name"
													label="Name"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel id="type-label">Type</InputLabel>
												<Select
													value={values.type}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.type && touched.type}
													name="type"
													label="Type"
													labelId="type-label"
													MenuProps={{ variant: 'menu' }}
												>
													<MenuItem value="extension">Extension</MenuItem>
													<MenuItem value="app">Desktop App</MenuItem>
													<MenuItem value="launcher">Mobile Launcher</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>
												Desktop Apps Will track downloads, installs and
												report conversions back to google ads
											</Blockquote>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset">
									<Grid container spacing={3}>
										<Grid item xs={3}>
											<Typography variant="h5">Produc icon*</Typography>
											<Box mb={1} />
											<Typography variant="body2">128x128 pixels</Typography>
											<Typography className={classes.label} variant="caption">
												An icon to visually identify this product
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<ImageUpload
												image={iconFile}
												type="icon"
												text="Upload Icon"
												onChange={setIconFile}
											/>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</section>
					<section>
						<Grid container spacing={3}>
							<Grid item xs={2}>
								<Typography variant="h5">Settings</Typography>
							</Grid>
							<Grid item xs={8}>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.search_url}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.search_url && touched.search_url}
													helperText={
														errors.search_url &&
														touched.search_url &&
														errors.search_url
													}
													name="search_url"
													label="Search URL"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>The Money Maker</Blockquote>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.search_url_dsp}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.search_url_dsp && touched.search_url_dsp}
													helperText={
														errors.search_url_dsp &&
														touched.search_url_dsp &&
														errors.search_url_dsp
													}
													name="search_url_dsp"
													label="Search URL for Display"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}></Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.search_url_srch}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.search_url_srch && touched.search_url_srch}
													helperText={
														errors.search_url_srch &&
														touched.search_url_srch &&
														errors.search_url_srch
													}
													name="search_url_srch"
													label="Search URL for Search"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}></Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.pixel}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.pixel && touched.pixel}
													helperText={
														errors.pixel &&
														touched.pixel &&
														errors.pixel
													}
													name="pixel"
													label="Pixel 100%"
													multiline
													rows={5}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>
												This pixel will fire on every install
											</Blockquote>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.pixel_50}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting}
													error={errors.pixel_50 && touched.pixel_50}
													helperText={
														errors.pixel_50 &&
														touched.pixel_50 &&
														errors.pixel_50
													}
													name="pixel_50"
													label="Pixel 50%"
													multiline
													rows={5}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>
												This pixel will fire only on 50% of the installs
											</Blockquote>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset">
									<Grid container spacing={3}>
										<Grid item xs={3}>
											<Typography variant="h5">Background*</Typography>
											<Box mb={1} />
											<Typography variant="body2">
												2000x1500 pixels
											</Typography>
											<Typography className={classes.label} variant="caption">
												Default Background to show when background is
												activated
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<ImageUpload
												image={backgroundFile}
												type="image"
												text="Upload Image"
												onChange={setBackgroundFile}
											/>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</section>
					<section>
						<Grid container spacing={3}>
							<Grid item xs={2}>
								<Typography variant="h5">Visual Settings</Typography>
							</Grid>
							<Grid item xs={8}>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel id="hideTopBarBy-label">
													Hide top bar by:
												</InputLabel>
												<Select
													value={values.hideTopBarBy}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting || !values.hideTopBar}
													error={
														errors.hideTopBarBy && touched.hideTopBarBy
													}
													name="hideTopBarBy"
													label="Hide top bar by:"
													labelId="hideTopBarBy-label"
													MenuProps={{ variant: 'menu' }}
												>
													<MenuItem value="hits">Hits</MenuItem>
													<MenuItem value="days">Days</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>You can hide the top bar after N hits (loads) or N days after the user interacted whit it</Blockquote>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControl fullWidth>
												<TextField
													value={values.hideTopBarFrequency}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={isSubmitting || !values.hideTopBar}
													error={
														errors.hideTopBarFrequency &&
														touched.hideTopBarFrequency
													}
													helperText={
														errors.hideTopBarFrequency &&
														touched.hideTopBarFrequency &&
														errors.hideTopBarFrequency
													}
													name="hideTopBarFrequency"
													label="Frequency"
													variant="outlined"
													type="number"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>
												(Number) N days or N hits
											</Blockquote>
										</Grid>
									</Grid>
								</Box>
								<Box component="fieldset" mb={5}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<FormControlLabel
												control={
													<Switch
														checked={values.hideTopBar}
														onChange={handleChange}
														color="primary"
														name="hideTopBar"
														inputProps={{
															'aria-label': 'primary checkbox',
														}}
													/>
												}
												label="Hide top bar"
												labelPlacement="start"
											/>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={5}>
											<Blockquote>
												Unchecked this if you don't want to hide the top bar definitly
											</Blockquote>
										</Grid>
									</Grid>
								</Box>

							</Grid>
						</Grid>
					</section>

					<footer>
						{isSubmitting && <LinearProgress className={classes.progress} />}

						<Button
							onClick={handleSubmit}
							disabled={isSubmitting}
							variant="contained"
							color="primary"
						>
							Save Changes
						</Button>
					</footer>
				</>
			)}
		</Formik>
	);
};

export default Form;
