import { SET_MAIN_DRAWER_STATUS, SET_STATS_FILTER } from './types';

export const setMainDrawerStatus = ( status ) => {
	return ( dispatch ) => {
		dispatch( {
			type: SET_MAIN_DRAWER_STATUS,
			payload: status,
		} );
	};
};

export const setStatsFilter = ( filter, startDate = '', endDate = '' ) => {
	return {
		type: SET_STATS_FILTER,
		payload: {
			filter,
			startDate,
			endDate
		}
	};
};
