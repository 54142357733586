import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';

import logo from '../images/tomato-soup.png';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(5),
		boxShadow: 'none',
		borderRadius: 8,
		border: '1px solid #dadce0',
		width: 450,
		// minHeight: 505,
	},
	logo: {
		height: 25,
		marginBottom: 10,
	},
	forgot: {
		paddingLeft: '9px !important',
		paddingRight: '9px !important',
		marginLeft: -4,
	},
	form: {
		marginTop: 40,
		paddingBottom: 40
	}
}));

const Login = () => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);

	const handleChangeShowPassword = () => {
		setShowPassword(!showPassword);
	};
	return (
		<>
			<Grid spacing={3} container justifyContent="center" alignItems="center">
				<Paper className={classes.paper}>
					<Grid direction="column" container alignItems="center" justifyContent="center">
						<img src={logo} alt="Seitz Network" className={classes.logo} />
						<Typography variant="h2">Welcome</Typography>
					</Grid>

					<form action="/login" method="POST" className={classes.form}>
						{/* <Box component="fieldset" mt={5} mb={3}>
							<Typography variant="body2">
								To continue, first verify it’s you
							</Typography>
						</Box> */}
						{window.app && window.app.errors && (
							<Box component="fieldset" mb={2}>
								<Alert severity="error">Incorrect Credentials</Alert>
							</Box>
						)}
						<Box component="fieldset" mb={2}>
							<FormControl fullWidth>
								<TextField
									error={window.app && window.app.errors}
									type="email"
									name="email"
									label="Email"
									variant="outlined"
								/>
							</FormControl>
						</Box>
						<Box component="fieldset" mb={1}>
							<FormControl fullWidth>
								<TextField
									error={window.app && window.app.errors}
									type={showPassword ? 'text' : 'password'}
									label="Password"
									name="password"
									variant="outlined"
								/>
							</FormControl>
						</Box>

						<Box component="fieldset" mt={2}>
							<Grid container justifyContent="space-between">
								{/* <Button href="/forgot" className={classes.forgot} color="primary">
									Forgot password?
								</Button> */}
								<FormControlLabel
									control={
										<Checkbox
											checked={showPassword}
											onChange={handleChangeShowPassword}
											name="showPassword"
											color="primary"
										/>
									}
									label={<Typography variant="body2">Show password</Typography>}
								/>
								<Button type="submit" variant="contained" color="primary">
									Log in
								</Button>
							</Grid>
						</Box>
						<input
							type="hidden"
							name="_token"
							value={window.app ? window.app._token : ''}
						/>
					</form>
				</Paper>
			</Grid>
		</>
	);
};

export default Login;
