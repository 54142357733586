import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( ( theme ) => ( {
	chart: {},
} ) );

const TopBarChart = ( { stats, colors } ) => {
	const classes = useStyles();
	const canvasRef = useRef( null );

	useEffect( () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext( '2d' );

		var gradient = context.createLinearGradient( 0, 0, 0, 175 );
		gradient.addColorStop( 0, 'rgba(230, 142, 27,1)' );
		gradient.addColorStop( 1, 'rgba(230, 142, 27,0)' );

		const config = {
			type: 'line',
			data: {
				labels: stats.data.labels,
				datasets: [
					{
						data: stats.data.data['Total Count'],
						borderColor: 'rgb(230, 142, 27)',
						fill: true,
						backgroundColor: gradient,
						label: 'Total'
					},
					{
						data: stats.data.data['No UID'],
						borderColor: '#eee',
						label: 'No UID'
					},
					{
						data: stats.data.data['Unique'],
						borderColor: '#000',
						label: 'Unique'
					},
				],
			},
			options: {
				responsive: true,
				elements: {
					line: {
						tension: 0.4,
					},
				},
				plugins: {
					title: {
						display: false,
					},
					legend: {
						display: false,
					},
					tooltip: {
						intersect: false,
					},
					filler: {
						propagate: true,
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
					y: {
						beginAtZero: true,
						grid: {
							display: false,
						},
					},
				},
			},
		};

		new Chart( context, config );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] );

	return (
		<div className={classes.chart}>
			<canvas ref={canvasRef} height="60"></canvas>
		</div>
	);
};
export default TopBarChart;
