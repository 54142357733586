import {
	GET_ADS,
	GET_ADS_ERROR,
	GET_ADS_SUCCESS,
	ADD_NEW_AD,
	ADD_NEW_AD_ERROR,
	ADD_NEW_AD_SUCCESS,
	EDIT_AD,
	EDIT_AD_ERROR,
	EDIT_AD_SUCCESS,
	DELETE_AD,
	DELETE_AD_ERROR,
	DELETE_AD_SUCCESS,
} from './types';

import { fetchAllAds, fetchAddAd, fetchDeleteAd, fetchEditAd } from '../../api';

export const getAllAds = ( product_id ) => {
	return ( dispatch ) => {
		dispatch( { type: GET_ADS } );

		fetchAllAds(
			product_id,
			( data ) => {
				dispatch( { type: GET_ADS_SUCCESS, payload: data } );
			},
			( error ) => {
				dispatch( { type: GET_ADS_ERROR, payload: error.message } );
			}
		);
	};
};

export const addNewAd = ( data, success, error ) => {
	return ( dispatch ) => {
		dispatch( { type: ADD_NEW_AD } );

		fetchAddAd(
			data,
			( data ) => {
				dispatch( { type: ADD_NEW_AD_SUCCESS, payload: data } );
				dispatch( getAllAds( data.product_id ) );
				success( data );
			},
			( err ) => {
				dispatch( { type: ADD_NEW_AD_ERROR, payload: err.message } );
				error( err );
			}
		);
	};
};

export const deleteAd = ( data, success, error ) => {
	return ( dispatch ) => {
		dispatch( { type: DELETE_AD } );

		fetchDeleteAd(
			data,
			( data ) => {
				dispatch( { type: DELETE_AD_SUCCESS, payload: data } );
				dispatch( getAllAds( data.product_id ) );
				success( data );
			},
			( err ) => {
				dispatch( { type: DELETE_AD_ERROR, payload: err.message } );
				error( err );
			}
		);
	};
};

export const editAd = ( data, success, error ) => {
	return ( dispatch ) => {
		dispatch( { type: EDIT_AD } );

		fetchEditAd(
			data,
			( data ) => {
				dispatch( { type: EDIT_AD_SUCCESS, payload: data } );
				dispatch( getAllAds( data.product_id ) );
				success( data );
			},
			( err ) => {
				dispatch( { type: EDIT_AD_ERROR, payload: err.message } );
				error( err );
			}
		);
	};
};
