import {
	GET_PRODUCTS,
	GET_PRODUCTS_ERROR,
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCT,
	GET_PRODUCT_ERROR,
	GET_PRODUCT_SUCCESS,
	EDIT_PRODUCT_SUCCESS
} from '../action-creators/types';

const INITIAL_STATE = {
	allProducts: [],
	loading: true,
	error: false,
	selectedProduct: {
		data: false,
		loading: true,
		error: false,
	},
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PRODUCTS:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_PRODUCTS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case GET_PRODUCTS_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				allProducts: action.payload,
			};
		case GET_PRODUCT:
			return {
				...state,
				selectedProduct: {
					data: false,
					loading: true,
					error: false,
				},
			};
		case GET_PRODUCT_SUCCESS:
		case EDIT_PRODUCT_SUCCESS:
			return {
				...state,
				selectedProduct: {
					data: action.payload,
					loading: false,
					error: false,
				},
			};
		case GET_PRODUCT_ERROR:
			return {
				...state,
				selectedProduct: {
					data: false,
					loading: false,
					error: action.payload,
				},
			};
		default:
			return state;
	}
};
export default reducer;
