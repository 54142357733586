/**
 * APP
 */
export const SET_MAIN_DRAWER_STATUS = 'SET_MAIN_DRAWER_STATUS';
export const SET_STATS_FILTER = 'SET_STATS_FILTER';

/**
 * PRODUCTS
 */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const SELECT_PRODUCT = 'SELECT_PRODUCT';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_ERROR = 'EDIT_PRODUCT_ERROR';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';

export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT';
export const ADD_NEW_PRODUCT_SUCCESS = 'ADD_NEW_PRODUCT_SUCCESS';
export const ADD_NEW_PRODUCT_ERROR = 'ADD_NEW_PRODUCT_ERROR';

/**
 * SHORTCUTS
 */
export const GET_SHORTCUTS = 'GET_SHORTCUTS';
export const SET_SHORTCUTS = 'SET_SHORTCUTS';
export const GET_SHORTCUTS_ERROR = 'GET_SHORTCUTS_ERROR';
export const GET_SHORTCUTS_SUCCESS = 'GET_SHORTCUTS_SUCCESS';

export const ADD_NEW_SHORTCUT = 'ADD_NEW_SHORTCUT';
export const ADD_NEW_SHORTCUT_ERROR = 'ADD_NEW_SHORTCUT_ERROR';
export const ADD_NEW_SHORTCUT_SUCCESS = 'ADD_NEW_SHORTCUT_SUCCESS';

export const EDIT_SHORTCUT = 'EDIT_SHORTCUT';
export const EDIT_SHORTCUT_ERROR = 'EDIT_SHORTCUT_ERROR';
export const EDIT_SHORTCUT_SUCCESS = 'EDIT_SHORTCUT_SUCCESS';

export const DELETE_SHORTCUT = 'DELETE_SHORTCUT';
export const DELETE_SHORTCUT_ERROR = 'DELETE_SHORTCUT_ERROR';
export const DELETE_SHORTCUT_SUCCESS = 'DELETE_SHORTCUT_SUCCESS';

/**
 * ADS
 */
export const GET_ADS = 'GET_ADS';
export const SET_ADS = 'SET_ADS';
export const GET_ADS_ERROR = 'GET_ADS_ERROR';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';

export const ADD_NEW_AD = 'ADD_NEW_AD';
export const ADD_NEW_AD_ERROR = 'ADD_NEW_AD_ERROR';
export const ADD_NEW_AD_SUCCESS = 'ADD_NEW_AD_SUCCESS';

export const EDIT_AD = 'EDIT_AD';
export const EDIT_AD_ERROR = 'EDIT_AD_ERROR';
export const EDIT_AD_SUCCESS = 'EDIT_AD_SUCCESS';

export const DELETE_AD = 'DELETE_AD';
export const DELETE_AD_ERROR = 'DELETE_AD_ERROR';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';

/**
 * STATS
 */
export const GET_STATS = 'GET_STATS';
export const GET_STATS_ERROR = 'GET_STATS_ERROR';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
