import {
	GET_SHORTCUTS,
	GET_SHORTCUTS_ERROR,
	GET_SHORTCUTS_SUCCESS,
	ADD_NEW_SHORTCUT,
	ADD_NEW_SHORTCUT_ERROR,
	ADD_NEW_SHORTCUT_SUCCESS,
	EDIT_SHORTCUT,
	EDIT_SHORTCUT_ERROR,
	EDIT_SHORTCUT_SUCCESS,
	DELETE_SHORTCUT,
	DELETE_SHORTCUT_ERROR,
	DELETE_SHORTCUT_SUCCESS,
} from '../action-creators/types';

const INITIAL_STATE = {
	shortcuts: [],
	loading: true,
	messageError: '',
	isWaitingAction: false,
	messageSuccess: '',
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_SHORTCUTS:
			return {
				...state,
				messageError: '',
				loading: true,
			};
		case GET_SHORTCUTS_ERROR:
			return {
				...state,
				loading: false,
				messageError: action.payload,
			};
		case GET_SHORTCUTS_SUCCESS:
			return {
				...state,
				messageError: '',
				loading: false,
				shortcuts: action.payload,
			};
		case ADD_NEW_SHORTCUT:
			return {
				...state,
				messageError: '',
				isWaitingAction: true,
				messageSuccess: '',
			};
		case ADD_NEW_SHORTCUT_ERROR:
			return {
				...state,
				messageError: action.payload,
				isWaitingAction: false,
				messageSuccess: '',
			};
		case ADD_NEW_SHORTCUT_SUCCESS:
			return {
				...state,
				messageError: '',
				isWaitingAction: false,
				messageSuccess: 'Shortcut added succesfuly!',
			};
		case EDIT_SHORTCUT:
			return {
				...state,
				messageError: '',
				isWaitingAction: true,
				messageSuccess: '',
			};
		case EDIT_SHORTCUT_ERROR:
			return {
				...state,
				messageError: action.payload,
				isWaitingAction: false,
				messageSuccess: '',
			};
		case EDIT_SHORTCUT_SUCCESS:
			return {
				...state,
				messageError: '',
				isWaitingAction: false,
				messageSuccess: 'Shortcut edited succesfuly!',
			};
		case DELETE_SHORTCUT:
			return {
				...state,
				messageError: '',
				isWaitingAction: true,
				messageSuccess: '',
			};
		case DELETE_SHORTCUT_ERROR:
			return {
				...state,
				messageError: action.payload,
				isWaitingAction: false,
				messageSuccess: '',
			};
		case DELETE_SHORTCUT_SUCCESS:
			return {
				...state,
				messageError: '',
				isWaitingAction: false,
				messageSuccess: 'Shortcut deleted succesfuly!',
			};
		default:
			return state;
	}
};
export default reducer;
