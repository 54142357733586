import React, { useState, useEffect } from 'react';
import moment from 'moment';

// State
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/index';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

// DatePickers
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

//Custom Components
import Blockquote from '../../../components/Blockquote';
import Loading from '../../../components/Loading';
import Charts from './Charts';

const useStyles = makeStyles( ( theme ) => ( {
	formControl: {
		minWidth: 220,
	},
	selectEmpty: {
		marginTop: theme.spacing( 2 ),
	},
	datePicker: {
		marginTop: 0
	}
} ) );

const RenderChart = ( { type, title, description, product_id, filter, ...props } ) => {
	const stats = useSelector( state => state.stats[type] );

	//actions
	const dispatch = useDispatch();
	const { getStats } = bindActionCreators( actionCreators, dispatch );

	useEffect( () => {
		getStats( { type, product_id } )
	}, [filter] );

	if( stats.loading ) {
		return <Loading />;
	}

	if( stats.messageError ) {
		return <Alert severity="error">{stats.messageError}</Alert>;
	}

	return (
		<>
			<Typography variant="h5">{title}</Typography>
			<Box mt={1} mb={2}>
				{description && <Blockquote>{description}</Blockquote>}
			</Box>
			<Charts stats={stats} {...props} />
		</>
	);
};

const Stats = () => {
	const classes = useStyles();

	//actions
	const dispatch = useDispatch();
	const { setStatsFilter } = bindActionCreators( actionCreators, dispatch );

	const { filter, startDate, endDate } =
		useSelector( ( state ) => state.app );

	//local state
	const [displayCustomDates, setDisplayCustomDates] = useState( filter == 3 );
	const [filterDateStart, setFilterDateStart] = useState( startDate ? moment( startDate, 'YYYY-MM-DD' ) : moment().subtract( 30, 'days' ) );
	const [filterDateEnd, setFilterDateEnd] = useState( endDate ? moment( endDate, 'YYYY-MM-DD' ) : moment() );

	const product_id = useSelector( ( state ) => state.products.selectedProduct.data.id );
	const product_type = useSelector( ( state ) => state.products.selectedProduct.data.type );

	const [updateStats, setUpdateStats] = useState( filter );

	useEffect( () => {
		if( filter == 3 ) {
			if( startDate && endDate ) {
				setUpdateStats( updateStats + 1 );
			}
		} else {
			setUpdateStats( filter );
		}
	}, [filter, startDate, endDate] );

	//handle actions
	const handleFilterChange = ( event ) => {
		if( event.target.value == 3 ) {
			setDisplayCustomDates( true );
		} else {
			setDisplayCustomDates( false );
		}
		setStatsFilter( event.target.value );
	};

	const handleDateGetStatsClick = ( e ) => {
		setStatsFilter( 3, filterDateStart.format( 'YYYY-MM-DD' ), filterDateEnd.format( 'YYYY-MM-DD' ) );
	};

	const handleDateStartChange = ( date ) => {
		setFilterDateStart( date );
	};

	const handleDateEndChange = ( date ) => {
		setFilterDateEnd( date );
	};

	return (
		<>
			<header>
				<Typography variant="h2">Stats</Typography>
			</header>

			<section>
				<Box mb={3}>
					<Grid spacing={3} container>
						<Grid item>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel>Filter by</InputLabel>
								<Select
									label="Filter by"
									value={filter}
									onChange={handleFilterChange}
								>
									<MenuItem value={1}>Last 30 days</MenuItem>
									<MenuItem value={2}>Last 60 days</MenuItem>
									<MenuItem value={3}>Custom</MenuItem>
									{/* <MenuItem value={0}>All time</MenuItem> */}
								</Select>
							</FormControl>
						</Grid>
						{displayCustomDates && (
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<Grid item>
									<KeyboardDatePicker
										className={classes.datePicker}
										disableToolbar
										variant="inline"
										format="YYYY-MM-DD"
										margin="normal"
										id="date-picker-start"
										label="Start Date"
										value={filterDateStart}
										onChange={handleDateStartChange}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										inputVariant="outlined"
									/>
								</Grid>
								<Grid item>
									<KeyboardDatePicker
										className={classes.datePicker}
										disableToolbar
										variant="inline"
										format="YYYY-MM-DD"
										margin="normal"
										id="date-picker-end"
										label="End Date"
										value={filterDateEnd}
										onChange={handleDateEndChange}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										inputVariant="outlined"
									/>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										color="primary"
										size="large"
										style={{ marginTop: 8 }}
										onClick={handleDateGetStatsClick}
									>
										Get Stats
									</Button>
								</Grid>
							</MuiPickersUtilsProvider>
						)}
					</Grid>
				</Box>
			</section>

			{/* {!chartsLoaded && <Loading />} */}

			{/* {chartsLoaded && ( */}
			<>
				<section>
					<Grid spacing={3} container>
						<Grid item md={12} lg={10} xl={8}>
							<RenderChart
								title="Downloads, Installs and Uninstalls"
								description="The number of times users have installed, donwloaded or uninstalled this item per day"
								type="installs"
								product_id={product_id}
								filter={updateStats}
							/>
						</Grid>
					</Grid>
				</section>
				<section>
					<Grid spacing={3} container>
						<Grid item md={12} lg={10} xl={8}>
							<RenderChart
								title="Daily Searches"
								description="The number of times users have made a search per day with in this extension"
								type="searches"
								product_id={product_id}
								filter={updateStats}
							/>
						</Grid>
					</Grid>
				</section>

				{product_type === 'app' ? (
					<>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="Chrome Downloads and Installs"
										type="chrome"
										product_id={product_id}
										filter={updateStats}
										mainColors={['#ff0600', '#b30400']}
									/>
								</Grid>
							</Grid>
						</section>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="IE Downloads and Installs"
										type="ie"
										product_id={product_id}
										filter={updateStats}
										mainColors={['#0012ff', '#000db3']}
									/>
								</Grid>
							</Grid>
						</section>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="Old Edge Downloads and Installs"
										type="old_edge"
										product_id={product_id}
										filter={updateStats}
										mainColors={['#008aff', '#0662b0']}
									/>
								</Grid>
							</Grid>
						</section>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="New Edge Downloads and Installs"
										type="new_edge"
										product_id={product_id}
										filter={updateStats}
										mainColors={['#00ddac', '#029877']}
									/>
								</Grid>
							</Grid>
						</section>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="Firefox Downloads and Installs"
										type="firefox"
										product_id={product_id}
										filter={updateStats}
										mainColors={['#ffa800', '#a96f00']}
									/>
								</Grid>
							</Grid>
						</section>
					</>
				) : (
					<>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="Daily Interactions"
										description="The number of times users have used the top bar per day"
										type="topbar"
										product_id={product_id}
										filter={updateStats}
									/>
								</Grid>
							</Grid>
						</section>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="Real Active Users"
										description="The number of unique users have load this new tab per day"
										type="users"
										product_id={product_id}
										filter={updateStats}
									/>
								</Grid>
							</Grid>
						</section>
						<section>
							<Grid spacing={3} container>
								<Grid item md={12} lg={10} xl={8}>
									<RenderChart
										title="Uninstalls per Keyword"
										type="keywords"
										product_id={product_id}
										filter={updateStats}
									/>
								</Grid>
							</Grid>
						</section>
					</>
				)}
			</>
			{/* )} */}
		</>
	);
};

export default Stats;
