import {
	GET_PRODUCTS,
	GET_PRODUCTS_ERROR,
	GET_PRODUCTS_SUCCESS,
	SELECT_PRODUCT,
	GET_PRODUCT,
	GET_PRODUCT_ERROR,
	GET_PRODUCT_SUCCESS,
	EDIT_PRODUCT,
	EDIT_PRODUCT_SUCCESS,
	EDIT_PRODUCT_ERROR,
	ADD_NEW_PRODUCT,
	ADD_NEW_PRODUCT_SUCCESS,
	ADD_NEW_PRODUCT_ERROR,
} from './types';

import { fetchAllProducts, fetchSingleProduct, fetchEditProduct, fetchAddProduct } from '../../api';

export const getAllProducts = () => {
	return (dispatch) => {
		dispatch({ type: GET_PRODUCTS });

		fetchAllProducts(
			(data) => {
				dispatch({ type: GET_PRODUCTS_SUCCESS, payload: data });
			},
			(error) => {
				dispatch({ type: GET_PRODUCTS_ERROR, payload: error });
			}
		);
	};
};

export const getSingleProduct = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_PRODUCT });

		fetchSingleProduct(
			id,
			(data) => {
				dispatch({ type: GET_PRODUCT_SUCCESS, payload: data });
			},
			(error) => {
				dispatch({ type: GET_PRODUCT_ERROR, payload: error });
			}
		);
	};
};

export const editProduct = (data, success, error) => {
	return (dispatch) => {
		dispatch({ type: EDIT_PRODUCT });

		fetchEditProduct(
			data,
			(data) => {
				dispatch({ type: EDIT_PRODUCT_SUCCESS, payload: data });
				success(data);
			},
			(err) => {
				dispatch({ type: EDIT_PRODUCT_ERROR, payload: err });
				error(err);
			}
		);
	};
};

export const addProduct = (data, success, error) => {
	return (dispatch) => {
		dispatch({ type: ADD_NEW_PRODUCT });

		fetchAddProduct(
			data,
			(data) => {
				dispatch({ type: ADD_NEW_PRODUCT_SUCCESS, payload: data });
				success(data);
			},
			(err) => {
				dispatch({ type: ADD_NEW_PRODUCT_ERROR, payload: err });
				error(err);
			}
		);
	};
};

export const selectProduct = (data) => {
	return (dispatch) => {
		dispatch({ type: SELECT_PRODUCT, payload: data });
	};
};
