import React from 'react';

// State

// Navigation
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';

// App Components

// App Pages
import Login from './pages/Login';
import Forgot from './pages/Forgot';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100vh',
	},
}));

const App = () => {
	const classes = useStyles();

	return (
		<Router>
			<div className={classes.root}>
				<Switch>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/forgot">
						<Forgot />
					</Route>
				</Switch>
			</div>
		</Router>
	);
};

export default App;
