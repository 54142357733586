import {
	GET_SHORTCUTS,
	GET_SHORTCUTS_ERROR,
	GET_SHORTCUTS_SUCCESS,
	ADD_NEW_SHORTCUT,
	ADD_NEW_SHORTCUT_ERROR,
	ADD_NEW_SHORTCUT_SUCCESS,
	EDIT_SHORTCUT,
	EDIT_SHORTCUT_ERROR,
	EDIT_SHORTCUT_SUCCESS,
	DELETE_SHORTCUT,
	DELETE_SHORTCUT_ERROR,
	DELETE_SHORTCUT_SUCCESS,
} from './types';

import {
	fetchAllShortcuts,
	fetchAddShortcut,
	fetchDeleteShortcut,
	fetchEditShortcut,
} from '../../api';

export const getAllShortcuts = ( product_id ) => {
	return ( dispatch ) => {
		dispatch( { type: GET_SHORTCUTS } );

		fetchAllShortcuts(
			product_id,
			( data ) => {
				dispatch( { type: GET_SHORTCUTS_SUCCESS, payload: data } );
			},
			( error ) => {
				dispatch( { type: GET_SHORTCUTS_ERROR, payload: error.message } );
			}
		);
	};
};

export const addNewShortcut = ( data, success, error ) => {
	return ( dispatch ) => {
		dispatch( { type: ADD_NEW_SHORTCUT } );

		fetchAddShortcut(
			data,
			( data ) => {
				dispatch( { type: ADD_NEW_SHORTCUT_SUCCESS, payload: data } );
				dispatch( getAllShortcuts( data.product_id ) );
				success( data );
			},
			( err ) => {
				dispatch( { type: ADD_NEW_SHORTCUT_ERROR, payload: err.message } );
				error( err );
			}
		);
	};
};

export const deleteShortcut = ( id, success, error ) => {
	return ( dispatch ) => {
		dispatch( { type: DELETE_SHORTCUT } );

		fetchDeleteShortcut(
			id,
			( data ) => {
				dispatch( { type: DELETE_SHORTCUT_SUCCESS, payload: data } );
				dispatch( getAllShortcuts( data.product_id ) );
				success( data );
			},
			( err ) => {
				dispatch( { type: DELETE_SHORTCUT_ERROR, payload: err.message } );
				error( err );
			}
		);
	};
};

export const editShortcut = ( data, success, error ) => {
	return ( dispatch ) => {
		dispatch( { type: EDIT_SHORTCUT } );

		fetchEditShortcut(
			data,
			( data ) => {
				dispatch( { type: EDIT_SHORTCUT_SUCCESS, payload: data } );
				dispatch( getAllShortcuts( data.product_id ) );
				success( data );
			},
			( err ) => {
				dispatch( { type: EDIT_SHORTCUT_ERROR, payload: err.message } );
				error( err );
			}
		);
	};
};
