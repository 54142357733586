import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createTheme({
	drawerWidth: 280,
	palette: {
		type: 'light',
		primary: {
			main: '#1b73e7',
			dark: '#2065c9',
		},
		secondary: {
			main: '#f8f9fa',
		},
		background: {
			default: '#fff',
		},
		textGrey: grey[600],
	},
	typography: {
		fontFamily: "'Roboto', sans-serif",
		fontSize: 14,
		h1: {
			fontFamily: "'Lato', sans-serif",
			fontSize: '2rem',
			fontWeight: 400,
		},
		h2: {
			fontFamily: "'Lato', sans-serif",
			fontSize: '1.75rem',
			fontWeight: 400,
		},
		h3: { fontFamily: "'Lato', sans-serif" },
		h4: { fontFamily: "'Lato', sans-serif" },
		h5: {
			fontFamily: "'Lato', sans-serif",
			fontSize: '1rem',
			fontWeight: 600,
		},
		h6: { fontFamily: "'Lato', sans-serif" },
	},
	overrides: {
		MuiAppBar: {
			root: {
				boxShadow: 'none',
			},
		},
		MuiButton: {
			root: {
				textTransform: 'initial',
				boxShadow: 'none !important',
				padding: '6px 25px !important',
			},
			outlined: {
				padding: '5px 25px !important',
				color: '#5f6368',
			},
		},
		MuiList: {
			padding: {
				paddingTop: 0,
			},
		},
		MuiListItem: {
			root: {
				marginBottom: 6,
				'&$selected': {
					backgroundColor: '#f8f9fa',
					color: '#1b73e7',
					'&:hover': {
						backgroundColor: '#fff',
					},
					'& .MuiListItemIcon-root, & .MuiTypography-root': {
						color: '#1b73e7',
					},
				},
			},
			button: {
				'&:hover': {
					backgroundColor: '#fff',
					color: '#1b73e7',
					'& .MuiListItemIcon-root, & .MuiTypography-root': {
						color: '#1b73e7',
					},
				},
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: 35,
			},
		},
		MuiListItemText: {
			root: {
				'& span, & svg': {
					fontSize: 14,
					fontFamily: "'Lato', sans-serif",
					fontWeight: 600,
					color: grey[600],
				},
			},
		},
		MuiTableHead: {
			root: {
				'& .MuiTableCell-head': {
					fontWeight: 400,
					fontSize: 12,
					color: '#5f6368',
					padding: '8px 16px',
					lineHeight: 1,
				},
			},
		},
		MuiTableRow: {
			root: {
				'&$hover': {
					'&:hover': {
						backgroundColor: '#f8f9fa',
					},
				},
			},
			hover: {
				cursor: 'pointer',
			},
		},
		MuiSelect: {
			root: {
				'&.MuiSelect-select:focus': {
					background: 'none',
				},
			},
		},
		MuiDialogActions: {
			root: {
				padding: 24,
			},
		},
		MuiFormControlLabel: {
			root: {
				margin: '0 !important',
			},
		},
	},
});
export default theme;
