import React from 'react';
import reportWebVitals from './reportWebVitals';

// Navigation
import ReactDOM from 'react-dom';

// State
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './state/store';

// MUI
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';

import './index.css';
import App from './App';
import Auth from './Auth';

const RenderApp = () => {
	const authpages = ['/login', '/forgot'];
	if (authpages.includes(window.location.pathname)) {
		return <Auth />;
	}
	return <App />;
};
ReactDOM.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<RenderApp />
			</PersistGate>
		</Provider>
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
