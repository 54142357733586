import React from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	progress: {
		border: 'none',
		paddingTop: 60,
		paddingBottom: 60,
        justifyContent: 'center',
        display:'flex'
	},
}));

export default function Loading() {
	const classes = useStyles();
    return (
        <Box className={classes.progress}>
            <CircularProgress size={35} />
        </Box>
    )
}