import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	footer: {
		padding: theme.spacing(5),
		textAlign: 'center',
		background: theme.palette.secondary.main,
	},
	text: {
		fontSize: 13,
		marginBottom: 0,
		color: theme.palette.textGrey,
	},
}));

const Footer = ({ onMenuPress }) => {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Typography className={classes.text} paragraph>
				© Copyright 2021 by SeitzNetwork. All Rights Reserved.
			</Typography>
		</footer>
	);
};
export default Footer;
