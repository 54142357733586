import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	chart: {},
}));

const LineChart = ({ stats, colors }) => {
	const classes = useStyles();
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		var gradient = context.createLinearGradient(0, 0, 0, 175);
		gradient.addColorStop(0, colors[0]);
		gradient.addColorStop(1, colors[1]);

		const config = {
			type: 'line',
			data: {
				labels: stats.data.labels,
				datasets: [
					{
						data: stats.data.data,
						borderColor: colors[0],
						fill: true,
						backgroundColor: gradient,
					},
				],
			},
			options: {
				responsive: true,
				elements: {
					line: {
						tension: 0.4,
					},
				},
				plugins: {
					title: {
						display: false,
					},
					legend: {
						display: false,
					},
					tooltip: {
						intersect: false,
					},
					filler: {
						propagate: true,
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
					y: {
						beginAtZero: true,
						grid: {
							display: false,
						},
					},
				},
			},
		};

		new Chart(context, config);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes.chart}>
			<canvas ref={canvasRef} height="60"></canvas>
		</div>
	);
};
export default LineChart;
