import React from 'react';

// MUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyle = makeStyles((theme) => ({
	form: {
		minWidth: 500,
		paddingTop: 4,
	},
	progress: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 6,
	},
}));

// Component
export default function ConfirmDialog({
	open,
	onClose,
	onSendForm,
	title,
	actionButtonText,
	description,
	data,
	loading,
}) {
	const classes = useStyle();

	var handleClose = (e, r) => {
		if (r === 'backdropClick') return;
		onClose(e);
	};

	const handleSubmit = () => {
		onSendForm(data);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<Box className={classes.form}>
				{loading && <LinearProgress className={classes.progress} />}
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					{description && (
						<DialogContentText id="alert-dialog-description">
							{description}
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						disabled={loading}
						onClick={handleSubmit}
						color="primary"
					>
						{actionButtonText}
					</Button>
					<Button disabled={loading} onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}
