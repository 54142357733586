import React from 'react';

// MUI
import { useTheme } from '@material-ui/core/styles';

//Custom Components
import LineChart from '../../components/charts/LineChart';
import StackedBarChart from '../../components/charts/StackedBarChart';
import ListChart from '../../components/charts/ListChart';
import TopBarChart from '../../components/charts/TopBarChart';

const Charts = ( { stats, ...props } ) => {
	const theme = useTheme();
	switch( stats.data.type ) {
		case 'installs':
			return <StackedBarChart stats={stats} {...props} />;
		case 'chrome':
			return <StackedBarChart stats={stats} {...props} />;
		case 'ie':
			return <StackedBarChart stats={stats} {...props} />;
		case 'old_edge':
			return <StackedBarChart stats={stats} {...props} />;
		case 'new_edge':
			return <StackedBarChart stats={stats} {...props} />;
		case 'firefox':
			return <StackedBarChart stats={stats} {...props} />;
		case 'keywords':
			return <ListChart stats={stats} {...props} />;
		case 'searches':
		case 'globalSearches':
			return (
				<LineChart
					colors={['rgba(74, 192, 192, 1)', 'rgba(74, 192, 192, 0)']}
					stats={stats}
					{...props}
				/>
			);
		case 'globalTopbar':
			return <TopBarChart colors={['rgba(27,115,230,1)', 'rgba(27,115,230,0)']} stats={stats} />;
		default:
			return <LineChart colors={['rgba(27,115,230,1)', 'rgba(27,115,230,0)']} stats={stats} />;
	}
};

export default Charts;
