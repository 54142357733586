import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers/index';

const persistConfig = {
	key: 'root',
	storage: storage,
	blacklist: ['shortcuts', 'ads', 'stats'],
};

const logger = createLogger( {
	collapsed: true
} );

const middleware = process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];
// const middleware = [thunk];

// const middleware = [thunk, logger];
const middlewares = compose( applyMiddleware( ...middleware ) );
const persistedReducer = persistReducer( persistConfig, reducers );

export const store = createStore( persistedReducer, {}, middlewares );
export const persistor = persistStore( store );
