import React from 'react';

// Navigation
import { useLocation, useRouteMatch, Link } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles( ( theme ) => ( {
	root: {
		width: '100%',
	},
	listItem: {
		margin: 0,
		paddingLeft: 24,
		paddingRight: 24,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: '#1b73e7',
			'& .MuiListItemIcon-root, & .MuiTypography-root': {
				color: '#1b73e7',
			},
		},
	},
	list: {
		marginTop: 5,
	},
} ) );

const Menu = () => {
	const classes = useStyles();
	let { url } = useRouteMatch();
	const location = useLocation();

	url = url.replace( /\/$/g, '' );

	const menuItems = [
		{ path: `${url}`, name: 'Details' },
		{ path: `${url}/shortcuts`, name: 'Shortcuts' },
		{ path: `${url}/ads`, name: 'Ads' },
		{ path: `${url}/stats`, name: 'Stats' },
	];

	return (
		<div className={classes.root}>
			<List className={classes.list} component="nav">
				{menuItems.map( ( item, index ) => {
					return (
						<ListItem
							key={index}
							className={classes.listItem}
							button
							component={Link}
							to={item.path}
							selected={location.pathname.replace( /\/$/g, '' ) === item.path}
						>
							<ListItemText primary={item.name} />
						</ListItem>
					);
				} )}
			</List>
		</div>
	);
};
export default Menu;
