import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';

import logo from '../images/tomato-soup.png';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(5),
		boxShadow: 'none',
		borderRadius: 8,
		border: '1px solid #dadce0',
		width: 450,
		height: 405,
	},
	logo: {
		height: 25,
		marginBottom: 10,
	},
	forgot: {
		paddingLeft: '9px !important',
		paddingRight: '9px !important',
		marginLeft: -4,
	},
}));

const Forgot = () => {
	const classes = useStyles();

	return (
		<>
			<Grid spacing={3} container justifyContent="center" alignItems="center">
				<Paper className={classes.paper}>
					<Grid direction="column" container alignItems="center" justifyContent="center">
						<img src={logo} alt="Seitz Network" className={classes.logo} />
						<Typography variant="h2">Recover Password</Typography>
					</Grid>

					<form action="/forgot" method="GET">
						<Box component="fieldset" mt={5} mb={3}>
							<Typography variant="body2">
								We'll send an email to reset your password
							</Typography>
						</Box>

						<Box component="fieldset" mb={2}>
							<FormControl fullWidth>
								<TextField
									error={window.app && window.app.errors}
									type="email"
									name="email"
									label="Email"
									variant="outlined"
								/>
							</FormControl>
						</Box>
						{(window.app && window.app.errors) && (
							<Box component="fieldset" mb={2}>
								<Alert severity="error">Invalid Email</Alert>
							</Box>
						)}
						<Box component="fieldset" mt={3}>
							<Grid container justifyContent="space-between">
								<Button href="/login" className={classes.forgot} color="primary">
									go back to login page
								</Button>
								<Button type="submit" variant="contained" color="primary">
									Recover Password
								</Button>
							</Grid>
						</Box>
						<input
							type="hidden"
							name="_token"
							value={window.app ? window.app._token : ''}
						/>
					</form>
				</Paper>
			</Grid>
		</>
	);
};

export default Forgot;
