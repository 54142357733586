import React from 'react';

// Navigation
import { useHistory } from 'react-router-dom';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//Custom Components
import ProductRow from './ProductRow';

const useStyles = makeStyles((theme) => ({
	progress: {
		border: 'none',
		paddingTop: 60,
	},
}));

const ProductsList = ({ products }) => {
	let history = useHistory();
	const classes = useStyles();

	const handleClick = (event, data) => {
		history.push(`/products/${data.id}/stats`);
	};

	if (products.length === 0) {
		return (
			<TableRow>
				<TableCell className={classes.progress} colSpan="6" align="center">
					No Products Found
				</TableCell>
			</TableRow>
		);
	}
	return products.map((row, index) => (
		<ProductRow product={row} key={index} onClick={(event) => handleClick(event, row)} />
	));
};

export default ProductsList;
