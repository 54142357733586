import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles( ( theme ) => ( {
	table: {
		width: '50%',
		tableLayout: 'auto',
	},
} ) );

const ListChart = ( { stats } ) => {
	const classes = useStyles();
	return (
		<Table className={classes.table} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell>Keywords</TableCell>
					<TableCell align="center">Uninstalls</TableCell>
					<TableCell align="center">Installs</TableCell>
					<TableCell align="center">Uninstall Rate</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{stats.data.data.map( ( row, index ) => (
					<TableRow key={index}>
						<TableCell component="th" scope="row">
							{row.keyword}
						</TableCell>
						<TableCell align="center">{row.uninstalls}</TableCell>
						<TableCell align="center">{row.installs}</TableCell>
						<TableCell align="center">{row.uninstall_rate}</TableCell>
					</TableRow>
				) )}
			</TableBody>
		</Table>
	);
};
export default ListChart;
