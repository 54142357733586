import { combineReducers } from 'redux';
import appReducer from './appReducer';
import productsReducer from './productsReducer';
import shortcutsReducer from './shortcutsReducer';
import adsReducer from './adsReducer';
import statsReducer from './statsReducer';

const reducers = combineReducers({
	app: appReducer,
	products: productsReducer,
	shortcuts: shortcutsReducer,
	ads: adsReducer,
	stats: statsReducer,
});

export default reducers;
