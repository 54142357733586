import React, { useState } from 'react';

// State
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/index';

// MUI
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

// Components
import Form from './Form';

const Details = () => {
	//local state
	const [successAlertOpen, setSuccessAlertOpen] = useState(false);
	const [errorAlertOpen, setErrorAlertOpen] = useState(false);

	//actions
	const dispatch = useDispatch();
	const { editProduct } = bindActionCreators(actionCreators, dispatch);

	//redux state
	const product = useSelector((state) => state.products.selectedProduct.data);

	const handleEditProduct = (data, callback) => {
		editProduct(
			{ ...data, product_id: product.id },
			() => {
				callback();
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setSuccessAlertOpen(true);
				setTimeout(() => {
					setSuccessAlertOpen(false);
				}, 5000);
			},
			(error) => {
				callback();
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setErrorAlertOpen(true);
				setTimeout(() => {
					setErrorAlertOpen(false);
				}, 5000);
			}
		);
	};

	return (
		<>
			<header>
				<Typography variant="h2">Details</Typography>
			</header>
			{successAlertOpen && (
				<section>
					<Alert severity="success">Product updated successfully!</Alert>
				</section>
			)}
			{errorAlertOpen && (
				<section>
					<Alert severity="error">
						There was a problem updating this data. Try again later
					</Alert>
				</section>
			)}
			<Form onSendForm={handleEditProduct} data={product} />
		</>
	);
};

export default Details;
