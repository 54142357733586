import { GET_STATS, GET_STATS_ERROR, GET_STATS_SUCCESS } from '../action-creators/types';

const INITIAL_STATE = {
	installs: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	searches: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	topbar: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	users: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	keywords: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	chrome: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	ie: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	old_edge: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	new_edge: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	firefox: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	globalSearches: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	globalUsers: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
	globalTopbar: {
		data: {},
		loading: true,
		messageError: '',
		messageSuccess: '',
	},
};

const reducer = ( state = INITIAL_STATE, action ) => {
	switch( action.type ) {
		case GET_STATS:
			return {
				...state,
				[action.payload.type]: {
					...state[action.payload.type],
					loading: true,
					messageError: '',
					messageSuccess: '',
				},
			};
		case GET_STATS_ERROR:
			return {
				...state,
				[action.payload.type]: {
					...state[action.payload.type],
					loading: false,
					messageError: action.payload,
					messageSuccess: '',
				},
			};
		case GET_STATS_SUCCESS:
			return {
				...state,
				[action.payload.type]: {
					...state[action.payload.type],
					loading: false,
					messageError: '',
					messageSuccess: `${action.payload.type} Stats loaded successfuly!`,
					data: action.payload,
				},
			};

		default:
			return state;
	}
};
export default reducer;
