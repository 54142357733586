import React from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

//Utils
import { FormatNumber } from '../../util';

const useStyles = makeStyles((theme) => ({
	product: {
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	label: {
		color: theme.palette.textGrey,
	},
}));

const ProductRow = ({ product, onClick }) => {
	const classes = useStyles();
	return (
		<TableRow hover onClick={onClick}>
			<TableCell component="th" scope="row">
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<Avatar variant="square" src={product.icon} />
					</Grid>
					<Grid item>
						<Typography className={classes.product} variant="body2">
							{product.name}
						</Typography>
						<Typography className={classes.label} variant="caption">
							Active Users: {FormatNumber(product.activeUsers)}
						</Typography>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell>{product.type}</TableCell>
			<TableCell>{FormatNumber(product.installs)}</TableCell>
			<TableCell>{FormatNumber(product.todayInstalls)}</TableCell>
			<TableCell>{FormatNumber(product.todayUninstalls)}</TableCell>
			<TableCell>{FormatNumber(product.todaySearches)}</TableCell>
		</TableRow>
	);
};

export default ProductRow;
