import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

const StackedBarChart = ( { stats, mainColors } ) => {
	const canvasRef = useRef( null );

	// const colors = ['#000', '#4ac0c0', '#36a2eb', '#eee'];
	const colors = {
		"Downloads": "#eee",
		"Installs": "#4ac0c0",
		"Uninstalls": "#36a2eb",
		"Install Rate": "#000",
		"Uninstall Rate": "#000",
	};

	const config = {
		type: 'bar',
		data: {
			labels: stats.data.labels,
			datasets: Object.keys( stats.data.data ).map( ( key ) => {
				let backgroundColor = colors[key];
				if( key == 'Uninstall Rate' && mainColors && mainColors[1] ) {
					backgroundColor = mainColors[1];
				} else if( key == 'Install Rate' && mainColors && mainColors[1] ) {
					backgroundColor = mainColors[1];
				} else if( key == 'Installs' && mainColors && mainColors[0] ) {
					backgroundColor = mainColors[0];
				}
				return {
					type: key == 'Uninstall Rate' || key == 'Install Rate' ? 'line' : 'bar',
					yAxisID: key == 'Uninstall Rate' || key == 'Install Rate' ? 'y1' : 'y',
					label: key,
					data: stats.data.data[key],
					backgroundColor: backgroundColor,
					barPercentage: 0.5,
					borderColor: backgroundColor
				};
			} ),
		},
		options: {
			plugins: {
				title: {
					display: false,
				},
				legend: {
					display: false,
				},
				tooltip: {
					intersect: false,
				},
			},
			responsive: true,
			scales: {
				x: {
					stacked: true,
					grid: {
						display: false,
					},
				},
				y: {
					type: 'linear',
					display: true,
					position: 'left',
					grid: {
						display: false,
					},
				},
				y1: {
					type: 'linear',
					display: true,
					position: 'right',
					grid: {
						display: false,
					},
				},
			},
		},
	};

	useEffect( () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext( '2d' );
		new Chart( context, config );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] );

	return (
		<div>
			<canvas ref={canvasRef} height="60"></canvas>
		</div>
	);
};
export default StackedBarChart;
