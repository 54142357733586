import axios from 'axios';
import { dashboard } from '../util';

export function fetchAllShortcuts( product_id, success, error ) {
	// data/shortcuts.json
	axios.get( `${dashboard}/api/products/${product_id}/shortcuts` ).then( res => success( res.data ) ).catch( error );
}

export function fetchAddShortcut( data, success, error ) {
	data._token = window.app && window.app._token ? window.app._token : '';
	axios.post( `${dashboard}/api/products/${data.product_id}/shortcuts/new`, data ).then( res => success( res.data ) ).catch( error );
}

export function fetchDeleteShortcut( data, success, error ) {
	data._token = window.app && window.app._token ? window.app._token : '';
	axios.post( `${dashboard}/api/products/${data.product_id}/shortcuts/${data.id}/delete`, data ).then( res => success( res.data ) ).catch( error );
}

export function fetchEditShortcut( data, success, error ) {
	data._token = window.app && window.app._token ? window.app._token : '';
	axios.post( `${dashboard}/api/products/${data.product_id}/shortcuts/${data.id}/update`, data ).then( res => success( res.data ) ).catch( error );
}
